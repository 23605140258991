import 'bootstrap';
import './async-forms';
import './modals/gallery-modals';
import './modals/editimage-modals';

import('./vendor/main_script');
import('./forms');
import('./scrolltop');
import('./datenblatt');
import('./lazyload');
import('./tooltips');
import('./login');
import('./settings');