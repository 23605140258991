export const getNewGalleryModal = _ => {
    const html = `
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="gallery-modal-label">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h4 class="modal-title" id="gallery-modal-label">Galerie-Auswahl</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="modal-gallery row"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
                    <button type="button" class="btn btn-primary btn-apply">Übernehmen</button>
                </div>
            </div>
        </div>
    </div>
    `;

    const imgTpl = `
    <div class="col-md-3 gallery-image">
        <div class="panel panel-default">
            <img src="" alt="Bild ID: " style="width:100%; height: 100px; object-fit: cover;">
            <div class="panel-footer" style="line-height:1.1;padding:5px 10px;">
                <small style="display:inline-block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;direction: rtl;text-align: left;width: 100%;"></small>
            </div>
        </div>
    </div>
    `;

    
    const $modal = $(html);
    const $modalGallery = $modal.find('.modal-gallery');
    const $btnApply = $modal.find('.modal-footer .btn-apply');

    let $images = $();

    const getImages = _ => $images;
    
    const addImage = (name, src) => {
        const $tpl = $(imgTpl);
        const $img = $tpl.find('img');
        const $small = $tpl.find('.panel-footer small');
        $tpl.data('image', name);
        $img.attr('src', src)
            .attr('alt', $img.attr('alt') + name);
        $small.text(name);
        for (let i=0; i<$images.length-1; i++) {
            const img1 = $($images.get(i)).data('image');
            const img2 = $($images.get(i+1)).data('image');
            let dec1, dec2, ins;
            if (img1 && img2) {
                dec1 = parseInt(img1, 16);
                dec2 = parseInt(img2, 16);
                ins  = parseInt($tpl.data('image'), 16);
                if (dec1 <= ins && ins <= dec2) {
                    $tpl.insertAfter($images.get(i));
                    $images = $images.add($tpl);
                    return;
                }
            }
        }
        $tpl.appendTo($modalGallery);
        $images = $images.add($tpl);
    };
    
    return { $modal, $btnApply, $images, addImage, getImages };
};
