import { defaults } from './defaults';
import { getNewGalleryModal } from './getNewGalleryModal';
import axios from 'axios';

$.fn.galleryModal = async function (opt) {
   const options = $.extend({}, defaults, opt);

   return this.each(function() {

      this._modal = null;
      const $this = $(this);

      if ($this.prop('tagName').toLowerCase() !== 'input') throw new Error("Gewähltes Element ist kein <input/> element");

      const inputName = $this.attr('name');
      const $parent = $this.parent();
      const $prev = $this.prev();
      $this.remove();
      const $el = $('<div></div>');
      const $inputGroup = $('<div class="input-group"></div>');
      const $maskInput = $this.clone().attr('name', null).addClass('form-control');
      const $input = $this.attr('type', 'hidden').attr('name', inputName).removeAttr('id');
      const $btnWrapper = $('<span></span>').addClass('input-group-btn');
      const $btn = $('<button></button>').addClass('btn btn-primary btn-modal');
      if ($this.hasClass('input-sm')) { $btn.addClass('btn-sm'), $btn.attr('id', 'btn-sm-'+inputName)};
      if ($this.hasClass('input-lg')) { $btn.addClass('btn-lg'), $btn.attr('id', 'btn-lg-'+inputName)};
      const $icon = $('<i></i>').addClass(`fa fa-${options.icon}`);

      if (options.disable) {
         $maskInput.attr('readonly', 'readonly');
      }

      // Methoden
      const getValue = () => $input.val();
      const setValue = value => $input.val(value);
      const setDisplayText = value => $maskInput.val(value);
      const setStatus = status => {
         $el.removeClass((_, className) => {
            const classes = [];
            if (className.trim().match(/^has-/) ) {
               classes.push(className);
            }
            return classes.join(' ');
         })
         .addClass(`has-${status}`);
         this._status = status;
      };

      // EventHandler anhängen
      $btn.click(async (e) => {
         e.preventDefault();
         setStatus(getValue());
         if (!this._modal) {
            this._modal = getNewGalleryModal();
            // Bilder hinzufügen
            const res = await axios.get(window.basepath + '/de_DE/image_list?api');
            console.log(res);
            
            if (res.data.success) {
               for (const name of res.data.data) {
                  const url = `${window.basepath}/de_DE/image_get?api&lowres&image=${encodeURIComponent(name)}`
                  this._modal.addImage(name, url);
               }
            }
         }
         const { $modal, $btnApply } = this._modal;
         const $images = this._modal.getImages();
         const lockApplyBtn = () => $btnApply.attr('disabled', 'disabled');
         const unlockApplyBtn = () => $btnApply.attr('disabled', false);

         lockApplyBtn();
         
         let $selected = null;
         $images.click(function() {
            const $panels = $images.find('.panel');
            $panels.css({ outline: 'none' });
            $(this).find('.panel').css({ outline: '7px solid #605ca877' });
            $selected = $(this);
            unlockApplyBtn();
         });

         $btnApply.click(() => {
            const image = $selected.data('image');
            setValue(image);
            setDisplayText(image);
            $modal.modal('hide');
         });


         // Modal nach dem Schließen aus dem DOM nehmen (Performance...)
         $modal.on('hidden.bs.modal', () => {
            $modal.detach();
         });

         // Modal ans DOM anheften und direkt öffnen
         $modal.appendTo('body').modal('show');

      });

      // Elemente zusammenfügen
      $inputGroup.append($input);
      $inputGroup.append($maskInput);
      $btn.append($icon);
      $btnWrapper.append($btn);
      $inputGroup.append($btnWrapper);
      if ($prev.length > 0) {
         $inputGroup.insertAfter($prev);
      } else {
         $inputGroup.appendTo($parent);
      }

      // Original-Element durch neues Element ersetzen
      $parent.append($el);

      // Bei manueller Eingabe das eigentliche Input aktualisieren
      $maskInput.on('keypress', _ => setTimeout(_ => $input.val($maskInput.val()), 10));

      return $maskInput;
   });

};
