import { defaults } from './defaults';
import { getNewEditimageModal } from './getNewEditimageModal';

import Vue from 'vue';
import VueCropper from 'vue-cropperjs';
//import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import Cropper from 'cropperjs';



$.fn.editImageModal = async function (opt) {

    const options = $.extend({}, defaults, opt);

    return this.each(function () {

        this._modal = null;
        this._cropper = null;
        const $this = $(this);
        const $picture = $this.closest('.picture');
        const $img = $picture.find('img');

        $this.click((e) => {
            e.preventDefault();

            const imgname = $picture.data('image');

            // Neues Modal instanziieren, wenn nicht existent
            if (!this._modal) {
                this._modal = getNewEditimageModal({
                    title: options.title,
                    size: options.size,
                    image: options.image,
                });

                Cropper.setDefaults({
                    autoCropArea: 1,
                    responsive: true,
                    data: { x: 0, y: 0, width: 9999, height: 9999 },
                });

                const $modal = this._modal.$modal;
                this._vue = new Vue({
                    el: this._modal.$cropper.get(0),
                    components: { VueCropper },
                    mounted() {
                        setTimeout(() => {
                            //this.$refs.cropper.setData({ x: 0, y: 0, width: 9999, height: 9999 });
                            $modal.find('.cropper-container.cropper-bg').css({ height: '300px', width: '100%', });
                        }, 200);
                    },
                    data: {
                        imgSrc: $img.attr('src'),
                        style: {
                            width: '100%',
                            height: '300px',
                        },
                        viewMode: 2,
                        responsive: true,
                        minCanvasWidth: 300,
                        minCanvasHeight: 300,
                    },
                    template: `<vue-cropper
                        ref="cropper"
                        :src="imgSrc"
                        alt="source Image"
                        :style="style"
                        :viewMode="viewMode"
                    ></vue-cropper>`
                });

                this._modal.$btnRotateLeft.click(() => {
                    this._vue.$refs.cropper.rotate(-90);
                });

                this._modal.$btnRotateRight.click(() => {
                    this._vue.$refs.cropper.rotate(90);
                });

                this._modal.$btnApply.click(() => {
                    const picture = this._vue.$refs.cropper.getCroppedCanvas().toDataURL();
                    console.log(picture);
                    const machineno = $('#global-machineno').val();
                    const adressid = $('#global-adressid').val();

                    axios.post('?api&p=internal_image_upload', {
                        picture,
                        adressid,
                        machineno,
                        filename: imgname,
                    })
                    .catch(err => toastr.error(err))
                    .finally(() => window.location.reload());
                });
            }
            const $modal = this._modal.$modal;

            $modal.appendTo('body')
                .on('hidden.bs.modal', function () {
                    $(this).detach();                    
                })
                .modal({
                    keyboard: false,
                    show: true,
                    backdrop: 'static',
                });
        });

        return $this;
    });
};
