export const getNewEditimageModal = (opts) => {
    const $modal = $(`<div id="search-modal" class="modal fade" role="dialog"></div>`);
    const $modalDialog = $(`<div class="modal-dialog" role="document"></div>`).addClass(`modal-lg`);
    const $modalContent = $(`<div class="modal-content"></div>`);
    const $modalHeader = $(`<div class="modal-header"></div>`);
    const $closeBtn = $(`<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>`);
    const $modalTitle = $(`<div class="modal-title">${opts.title}</div>`);
    const $modalBody = $(`<div class="modal-body" style="position: relative;"></div>`).css({ overflowX: 'auto' });

    //const $canvas = $(`<canvas></canvas>`).css({ width: '100%', height: '400px' });
    //const ctx = $canvas.get(0).getContext('2d');
    const $cropper = $('<div></div>')

    const $controls = $('<div class="controls"></div>');
    const $btnRotateLeft = $(`<button>-90°</button>`);
    const $btnRotateRight = $(`<button>+90°</button>`);

    const $modalFooter = $(`<div class="modal-footer"></div>`);
    const $btnClose = $(`<button type="button" class="btn btn-link" data-dismiss="modal">Schließen</button>`);
    const $btnApply = $(`<button type="button" class="btn btn-default">Übernehmen</button>`);


    $modal.append(
        $modalDialog.append(
            $modalContent.append(
                $modalHeader.append($closeBtn, $modalTitle),
                $modalBody.append(
                    $cropper,
                    $controls.append($btnRotateLeft, $btnRotateRight),
                ),
                $modalFooter.append($btnClose, $btnApply),
            ),
        ),
    );

    return { $modal, $cropper, $btnRotateLeft, $btnRotateRight, $btnApply };
};
