import axios from 'axios';

window.$.fn.serializeObject = function () {
    return Object.fromEntries(this.serializeArray().map(x => [x.name, x.value]));
};

const submitHandler = async function (e) {
    e.preventDefault();
    const $this = $(this);
    const action = $this.attr('action');
    let payload;
    if ($this.data('ajax-json') !== undefined) {
        payload = $this.serializeObject();
    } else {
        payload = new FormData(this);
    }
    const cbs = $this.data('async:cb');
    try {
        const res = await axios.post(action, payload)
        if (res.data.success) {
            $this.find(':input').val('');
            for (const cb of cbs) {
                cb(undefined, res.data.data);
            }
        } else {
            for (const cb of cbs) {
                cb(res.data.errors.join('; '));
            }
        }
    } catch (e) {
        for (const cb of cbs) {
            cb('HTTP-Fehler')
        }
    }
};

window.$.fn.asyncForm = function (cb) {
    let tcb = this.data('async:cb');
    if (!tcb) tcb = [];
    tcb.push(cb.bind(this));
    this.data('async:cb', tcb);
    this.on('submit', submitHandler);
};